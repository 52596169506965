<template>
<v-container>
<div> 
  <h2 class="h2Style"><img class="oee" style="vertical-align:middle" src="../assets/wrenchBlack.svg"
                /> Agency Configuration</h2>
  <div>
  <v-card> 
    <v-data-table
      :headers="headersData"
      :items="operatorsData"
      :search="search"
      :items-per-page="15"
    >
    <template v-slot:item.actions="{ item }">
                <v-icon medium @click="editDoc(item)"> mdi-pencil </v-icon>
              </template>
    <template v-slot:top>
      <v-toolbar  flat > 
        <v-divider class="mx-4" inset vertical ></v-divider>
        <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-dialog  v-model="dialog" max-width="1000px" >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark @click="addDoc()"
              class="mb-2" v-bind="attrs" v-on="on" >
              Add
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline"><h4 class="h2Style">  </h4> </span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4" >
                    <v-text-field v-model="editedItem.agencyId" label="Agency ID"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" >
                    <v-text-field v-model="editedItem.name" label="Name"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" >
                    <v-text-field v-model="editedItem.GST" label="GST"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" >
                    <v-text-field v-model="editedItem.email" label="Email"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" >
                    <v-text-field v-model="editedItem.phone" label="Phone" @keypress="onlyNumber"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" >
                    <v-text-field v-model="editedItem.address" label="Address"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" >
                    <v-text-field v-model="editedItem.pincode" label="PIN" @keypress="onlyNumber"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" >
                    <v-text-field v-model="editedItem.salesExecutive" label="Sales Executive"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close" > Cancel </v-btn>
              <v-btn color="blue darken-1" text @click="save" > Submit </v-btn>
            </v-card-actions>

          </v-card>
        </v-dialog> 
      </v-toolbar>
    </template>
    </v-data-table>
  </v-card> 

  <div id="sucessAlert">
   <v-alert outlined type="success" text >
       Data Saved successfully.
   </v-alert>
   </div>
   <div id="errorAlert">
   <v-alert outlined type="warning" prominent border="left" >
       Error while saving the data.
   </v-alert>
   </div>
  
</div> 
</div>
</v-container>
</template> 

<script>  
import axios from "axios";
import constants from '../constants';
export default {
  name: 'pmPage', 
  data:function() {
    return {
      dialog: false,
      dialogDelete: false,
      search: '',
      headersData: [],
      operatorsData: [],
      editedIndex: -1,
      editedItem: {
        agencyId:'',
        name: '',
        GST: '',
        email: '',
        phone: '',
        address: '',
        pincode: '',
        salesExecutive: '',   
      },
      defaultItem: {
       agencyId:'',
        name: '',
        GST: '',
        email: '',
        phone: '',
        address: '',
        pincode: '',
        salesExecutive: '',
      },
      selectProps:{ 
      },
      machineSelected: '', 
      machineItems: [],
    };
  },
  watch: {
      dialog (val) {
        val || this.close()
      }, 

    },
  mounted() { 
    this.setGridData();
    this.selectProps= { 
      enable: ["Y","N"],
      frequency_durations: ["days","weeks","months"]
     }
  },
  methods: {
    onlyNumber ($event) { 
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
          $event.preventDefault();
      }
    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },            formatDateData (x)  {
                  var someDate = new Date();
                  someDate.setDate(someDate.getDate() + x); //number  of days to add, e.x. 15 days
                  var dateFormated = someDate.toISOString().substr(0,10);
                  console.log(dateFormated);
                  return dateFormated

                },
    save () { 
        if(this.editedItem.agencyId===null || this.editedItem.agencyId===""){
          alert("Please enter Agency ID"); 
          return;
        }else if(this.editedItem.name===null || this.editedItem.name===""){
          alert("Please Enter Agency Name"); 
          return;
        }                

        const req = { 
          agencyId: this.editedItem.agencyId,
          name: this.editedItem.name,
          GST:this.editedItem.GST,
          email:this.editedItem.email,
          phone:this.editedItem.phone,
          address: this.editedItem.address,
          pincode:this.editedItem.pincode,
          salesExecutive: this.editedItem.salesExecutive
        } 
        console.log("before checking edit or not",req);
        console.log("frequency duration",this.editedItem.frequency_duration);
        let url = this.isEdit 
        ? `${constants.backend_url}/updateAgency` 
        : `${constants.backend_url}/createAgency`;
        axios.post(url,req)
      .then((response) => { 
         console.log("response save operator data ", response.data);
         this.setGridData()
         document.querySelector("#sucessAlert").style.display = "none"; 
      }).catch((error)=>{
          console.log("error", error.message); 
          document.querySelector("#errorAlert").style.display = "block";
      });
      this.close()
    },        
    convert: (str)=> {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
},
    setGridData:function(){
       this.headersData=[
          { text: 'Agency ID', value: 'agencyId', class: 'indigo--text subtitle-1' },
          { text: 'Name', value: 'name'   , class: 'indigo--text subtitle-1' },
          { text: 'GST', value: 'GST', class: 'indigo--text subtitle-1' },
          { text: 'Email', value: 'email', class: 'indigo--text subtitle-1' },
          { text: 'Phone', value: 'phone' , class: 'indigo--text subtitle-1'},
          { text: 'Address', value: 'address' , class: 'indigo--text subtitle-1' },
          { text: 'Pincode', value: 'pincode' , class: 'indigo--text subtitle-1' },
          { text: 'Sales Executive', value: 'salesExecutive' , class: 'indigo--text subtitle-1'},
          { text: 'Actions', value: 'actions' , class: 'indigo--text subtitle-1'},
       ];
       axios
      .get(`${constants.backend_url}/agencies`)
      .then((response) => {
        console.log("incoming preventive maitainance schedule response of", response.data.data);
        this.operatorsData = response.data.data;

        
      }).catch((error)=>{
          console.log("error", error.message); 
      }); 
      },
      addDoc(){
        this.isEdit = false;
      },
      editDoc(item){
        console.log("trying to edit",item);
        this.isEdit = true;
        this.editedItem = item;
        this.dialog = true;
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.h2Style {
  color: #000000;
  padding: 12px;
}
 #sucessAlert{
  display: none;
 } 
 #errorAlert{
  display: none;
 }  
 .gridHeader{
   color:#0A47BA;
   font: bold;
 }
</style>